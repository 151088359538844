<template>
    <v-app>
        <v-app-bar app color="primary" dark>
            <div class="d-flex align-center">名单对比程序</div>
        </v-app-bar>
        <v-main>
            <v-container>
                <v-row>
                    <v-col cols="6">
                        <v-textarea
                            v-model="masterList"
                            outlined
                            label="总名单"
                            :hint="`${masterListNames.length}个名字`"
                            persistent-hint
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-textarea
                            v-model="checkingList"
                            outlined
                            label="需要对比的名单"
                            :hint="`${checkingListNames.length}个名字`"
                            persistent-hint
                        />
                    </v-col>
                    <v-col :cols="masterListMissing.length > 0 ? 6 : 12">
                        <v-textarea
                            filled
                            :value="checkingListMissing.join('\n')"
                            label="名单中少了这些人"
                            :hint="`${checkingListMissing.length}个名字`"
                            persistent-hint
                        />
                    </v-col>
                    <v-col cols="6" v-if="masterListMissing.length > 0">
                        <v-textarea
                            filled
                            :value="masterListMissing.join('\n')"
                            label="以下名字出现在了需要对比的名单中，但不在总名单中"
                            :hint="`${masterListMissing.length}个名字`"
                            persistent-hint
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import _ from 'lodash'
export default {
    components: {},
    data: () => ({
        masterList: "",
        checkingList: "",
    }),
    computed: {
        masterListNames() {
            if (!this.masterList.trim()) {
                return []
            }
            return this.masterList
                .trim()
                .split("\n")
                .map((i) => i.trim());
        },
        checkingListNames() {
            if (!this.checkingList.trim()) {
                return []
            }
            return this.checkingList
                .trim()
                .split("\n")
                .map((i) => i.trim());
        },
        checkingListMissing () {
            return _.difference(this.masterListNames, this.checkingListNames)
        },
        masterListMissing () {
            return _.difference(this.checkingListNames, this.masterListNames)
        },
    },
};
</script>